import React from 'react'
import Box from '@chakra-ui/core/dist/Box'

import { Feed } from './feed'

export default function App () {
  return (
    <Box width='100vw' minHeight='100vh' bg='gray.100'>
      <Feed />
    </Box>
  )
}
