import React from 'react'
import { FaGithub, FaChrome, FaTwitter } from 'react-icons/fa'
import { Button, Stack, Flex } from '@chakra-ui/core'
import Brand from './brand'

export default function Header () {
  return (
    <Flex justifyContent='space-between' alignItems='center' flexDirection={{ base: 'column', sm: 'row' }}>
      <Brand />
      <Stack isInline py={{ base: '10px', sm: '0px' }} w='100%' justifyContent={{ base: 'flex-start', sm: 'flex-end' }}>
        <Button leftIcon={FaGithub}>View Source</Button>
        {/* <Button variantColor='red' leftIcon={FaChrome}>Use Extension</Button> */}
        <Button variantColor='purple' leftIcon={FaTwitter}>Tweet</Button>
      </Stack>
    </Flex>
  )
}
