import { Box, Button, Flex, SimpleGrid } from '@chakra-ui/core'
import React, { useState, useEffect } from 'react'
import Filters from './components/filters'
import GroupTitle from './components/group-title'
import Header from './components/header'
import Repo from './components/repo'
import moment from 'moment'
import useFetch from 'use-http/dist'
import { PageLoader } from './components/page-loader'

function transformFilters ({ language, startDate, endDate }) {
  const transformedFilters = {}

  const languageQuery = language ? `language:${language} ` : ''
  const dateQuery = `created:${startDate}..${endDate}`

  transformedFilters.q = languageQuery + dateQuery
  transformedFilters.sort = 'stars'
  transformedFilters.order = 'desc'

  return transformedFilters
}

export function Feed () {
  // setting cachePolicy: 'no-cache' because of this issue:
  // https://github.com/ava/use-http/issues/177 , which sets loading as false
  // const { get, loading, error } = useFetch('https://api.github.com', { cachePolicy: 'no-cache' })
  const { get, loading, error } = useFetch('https://api.github.com')

  const [loadingMore, setLoadingMore] = useState(false)

  const [viewType, setViewType] = useState('grid')
  const [dateJump, setDateJump] = useState('day')
  const [language, setLanguage] = useState('JavaScript')
  const [repos, setRepos] = useState([])

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState(moment().subtract(1, 'day').format())

  useEffect(() => {
    const endDate = moment().subtract(1, 'day').format()
    const startDate = moment(endDate).subtract(1, dateJump).format()

    setEndDate(endDate)
    setStartDate(startDate)

    setRepos([])
  }, [dateJump, language])

  useEffect(() => {
    if (!startDate) {
      return
    }

    const filters = transformFilters({ startDate, endDate, language })
    const filtersQuery = new URLSearchParams(filters).toString()

    get(`/search/repositories?${filtersQuery}`).then(res => {
      setRepos([
        ...repos,
        {
          startDate,
          endDate,
          items: res.data.items
        }
      ])
    }).then(() => setLoadingMore(false))
  }, [startDate])

  return (
    <Box maxWidth='1200px' mx='auto' px='15px'>
      <Header />
      { repos.length === 0 && <PageLoader /> }

      <Flex alignItems='center' justifyContent='space-between' pt='25px' flexDirection={{base: 'column-reverse', md: 'row'}}>
        <GroupTitle startDate={repos?.[0]?.startDate} endDate={repos?.[0]?.endDate} />
        <Filters
          viewType={viewType}
          dateJump={dateJump}
          language={language}
          onViewTypeChange={setViewType}
          onDateJumpChange={setDateJump}
          onLanguageChange={setLanguage}
        />
      </Flex>

      {
        repos.map((repoGroup, counter) => {
          const groupTitle = counter > 0 && (
            <GroupTitle startDate={repoGroup.startDate} endDate={repoGroup.endDate} />
          )

          return (
            <Box key={counter}>
              {groupTitle}
              <SimpleGrid columns={viewType === 'list' ? 1 : [1, 2, 3]} spacing='15px'>
                {
                  repoGroup.items.map((item) => {
                    return (
                      <Repo key={item.id} repo={item} isListView={viewType === 'list'} />
                    )
                  })
                }
              </SimpleGrid>
            </Box>
          )
        })
      }

      <Flex alignItems='center' justifyContent='center' py='20px'>
        <Button isLoading={loadingMore} onClick={(e) => {
          e.target.blur()
          setLoadingMore(true)
          setEndDate(startDate)
          setStartDate(moment(startDate).subtract(1, dateJump).format())
        }} variantColor='blue'>Load next group</Button>
      </Flex>
    </Box>
  )
}
