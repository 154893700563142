import React from 'react'
import { Image, Flex, Heading, Text, Box } from '@chakra-ui/core'

export default function Brand () {
  return (
    <Flex alignItems='center' pt='15px' w='100%'>
      <Image src='/logo.svg' w='60px' mr='10px' />
      <Box>
        <Heading fontSize='24px'>Gitstar</Heading>
        <Text color='gray.600'>Most starred projects in Github</Text>
      </Box>
    </Flex>
  )
}
