import React from 'react'
import { Stack, Select, Menu, MenuButton, MenuList, MenuItem, Button, Icon, Box } from '@chakra-ui/core'
import { FaList, FaTable } from 'react-icons/fa'
import languages from '../data/languages.json'

export default function Filters (props) {
  const {
    onViewTypeChange,
    viewType,
    onDateJumpChange,
    dateJump,
    language,
    onLanguageChange
  } = props

  return (
    <Stack isInline spacing='10px' pb='20px' flexDirection={{ base: 'column', sm: 'row' }} alignSelf='flex-start'>
      <Select onChange={(e) => onLanguageChange(e.target.value)} placeholder={language} mb={{ base: '10px', sm: '' }}>
        {languages.map((language, idx) =>
          <option value={language.value} key={idx}>{language.title}</option>
        )}
      </Select>
      <Menu>
        <MenuButton
          as={Button}
          w='250px'
          bg='white'
          px='30px'
          fontWeight='normal'
          justifyContent='flex-start'
          borderWidth={1}
          _focus={{ boxShadow: 'none' }}
          mb={{ base: '10px', sm: '' }}
        >
          <Icon name='calendar' mr={3} />
          <Box as='span' textTransform='capitalize'>{dateJump}</Box>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => onDateJumpChange('day')}>Daily</MenuItem>
          <MenuItem onClick={() => onDateJumpChange('week')}>Weekly</MenuItem>
          <MenuItem onClick={() => onDateJumpChange('month')}>Monthly</MenuItem>
          <MenuItem onClick={() => onDateJumpChange('year')}>Yearly</MenuItem>
        </MenuList>
      </Menu>

      <Stack isInline spacing={0} ml='10px' height={{ base: '40px', sm: '' }}>
        <Button leftIcon={FaTable} bg={viewType === 'grid' ? 'gray.200' : 'white'} h='100%' fontWeight={400} roundedRight={0} onClick={() => onViewTypeChange('grid')}>Grid</Button>
        <Button leftIcon={FaList} bg={viewType === 'list' ? 'gray.200' : 'white'} h='100%' fontWeight={400} roundedLeft={0} onClick={() => onViewTypeChange('list')}>List</Button>
      </Stack>
    </Stack>
  )
}
