import React from 'react'
import { Box, Flex, Image, Heading, Text, Link, Button, Stack } from '@chakra-ui/core'
import { GoStar, GoRepoForked, GoIssueOpened } from 'react-icons/go'
import colors from '../data/colors.json'
import { FaCircle } from 'react-icons/fa'

export default function Repo ({ repo, isListView }) {
  return (
    <Flex
      borderWidth={1}
      bg='white'
      p='15px'
      borderRadius='15px'
      flexDirection={isListView ? 'row' : 'column'}
      justifyContent={isListView && 'space-between'}
      boxShadow='lg'
      maxWidth={!isListView && '370px'}
      wordBreak='break-word'
    >
      {
        !isListView &&
          <Flex mb='15px'>
            <Image
              src={repo.owner.avatar_url}
              height='35px'
              width='35px'
              rounded='50%'
              boxShadow='sm'
            />
            <Box ml='10px'>
              <Heading fontSize='16px'>
                {repo.owner.login}
              </Heading>
              <Text fontSize='13px'>
                <a href={repo.owner.html_url}>View Profile</a>
              </Text>
            </Box>
          </Flex>
      }

      <Box mb='15px'>
        <Box mb='10px'>
          <Heading as='a' fontSize='19px' href={repo.html_url} color='purple.700' target='_blank'>{repo.name}</Heading>
          <Text pt='10px' fontSize='13px' color='gray.600'>Built by &middot; <Link href={repo.owner.html_url} target='_blank' fontWeight='bold'>{repo.owner.login}</Link> &middot; {repo.updated_at}</Text>
        </Box>

        <Text fontSize='14px' color='gray.900' mb='10px'>
          {repo.description}
        </Text>

        <Stack isInline>
          <Box as={FaCircle} size='10px' color={colors[repo.language]} alignSelf='center' />
          <Text as='small' color='gray.700'>
            {repo.language}
          </Text>
          <Button as='a' cursor='pointer' leftIcon={GoStar} variant='link' fontSize='13px' iconSpacing='4px' _hover={{ textDecor: 'none' }}>{repo.stargazers_count}</Button>
          <Button as='a' cursor='pointer' leftIcon={GoRepoForked} variant='link' fontSize='13px' iconSpacing='4px' _hover={{ textDecor: 'none' }}>{repo.forks_count}</Button>
          <Button as='a' cursor='pointer' leftIcon={GoIssueOpened} variant='link' fontSize='13px' iconSpacing='4px' _hover={{ textDecor: 'none' }}>{repo.open_issues_count}</Button>
        </Stack>
      </Box>
      {
        isListView &&
          <Image
            src={repo.owner.avatar_url}
            height='105px'
            width='105px'
            rounded='100%'
            boxShadow='sm'
          />
      }
    </Flex>
  )
}
